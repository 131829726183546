<template>
  <main class="contractsRenewal">
    <div class="container">
      <div class="contractsRenewal__wrapper">
          <h2 class="contractsRenewal__mainHeading">
            <span>{{ activeProject?.name }}</span>
            <span class="tw-ps-2">{{ $t('contractsRenewal.mainHeading') }}</span>
          </h2>

          <div class="contractsRenewal__navigation">
            <button
              class="contractsRenewal__navItem"
              :class="{ active: activeStatus == 'needs_renewal' }"
              :disabled="isLoading"
              @click="activeStatus = 'needs_renewal'"
            >
              {{ $t('contractsRenewal.needRenewal') }}
              
              <skeleton-loader-vue
                v-if="isLoading"
                type="circle"
                :width="34"
                :height="34"
                animation="fade"
                rounded
              />
              <span
                v-if="!isLoading"
                class="contractsRenewal__navItemCount contractsRenewal__navItemCount--visible"
              >
               {{ listsCount?.needs_renewal }}
              </span>
            </button>
            <button
              class="contractsRenewal__navItem"
              :class="{ active: activeStatus == 'pending_talent' }"
              :disabled="isLoading"
              @click="activeStatus = 'pending_talent'"
            >
              {{ $t('contractsRenewal.inProgress') }}
              
              <skeleton-loader-vue
                v-if="isLoading"
                type="circle"
                :width="34"
                :height="34"
                animation="fade"
                rounded
              />
              <span
                v-if="!isLoading"
                class="contractsRenewal__navItemCount contractsRenewal__navItemCount--visible"
              >
                {{ listsCount?.pending_talent }}
                
              </span>
            </button>
            <button
              class="contractsRenewal__navItem"
              :class="{ active: activeStatus == 'renewed' }"
              :disabled="isLoading"
              @click="activeStatus = 'renewed'"
            >
              {{ $t('contractsRenewal.renewed') }}
              
              <skeleton-loader-vue
                v-if="isLoading"
                type="circle"
                :width="34"
                :height="34"
                animation="fade"
                rounded
              />
               <span
                v-if="!isLoading"
                class="contractsRenewal__navItemCount contractsRenewal__navItemCount--visible"
              >
              {{ listsCount?.renewed }}
              </span>
            </button>
            <button
              class="contractsRenewal__navItem"
              :class="{ active: activeStatus == 'rejected' }"
              :disabled="isLoading"
              @click="activeStatus = 'rejected'"
            >
              {{ $t('contractsRenewal.rejected') }}
              
              <skeleton-loader-vue
                v-if="isLoading"
                type="circle"
                :width="34"
                :height="34"
                animation="fade"
                rounded
              />
               <span
                v-if="!isLoading"
                class="contractsRenewal__navItemCount contractsRenewal__navItemCount--visible"
              >
                {{ listsCount?.rejected }}
              </span>
            </button>
          </div>

          <div v-if="talentCards.length > 1 && activeStatus == 'needs_renewal' && !isLoading" class="contractsRenewal__multipleSelect" :class="{ disabled: activeStatus != 'needs_renewal' }">
            <button @click="toggleSelectingCards">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <g :fill="!selecting ? '#422E87' : '#ff6f59'">
                  <path d="M13,20 L2,20 C0.9,20 0,19.1 0,18 L0,7 C0,6.4 0.4,6 1,6 C1.6,6 2,6.4 2,7 L2,18 L13,18 C13.6,18 14,18.4 14,19 C14,19.6 13.6,20 13,20 Z"/>
                  <path d="M18,16 L6,16 C4.9,16 4,15.1 4,14 L4,2 C4,0.9 4.9,0 6,0 L18,0 C19.1,0 20,0.9 20,2 L20,14 C20,15.1 19.1,16 18,16 Z M6,2 L6,14 L18,14 L18,2 L6,2 Z"/>
                  <path d="M15.7,6.7 C16.1,6.3 16.1,5.7 15.7,5.3 C15.3,4.9 14.7,4.9 14.3,5.3 L15.7,6.7 Z M11,10 L10.3,10.7 C10.7,11.1 11.3,11.1 11.7,10.7 L11,10 Z M9.7,7.3 C9.3,6.9 8.7,6.9 8.3,7.3 C7.9,7.7 7.9,8.3 8.3,8.7 L9.7,7.3 Z M14.3,5.3 L10.3,9.3 L11.7,10.7 L15.7,6.7 L14.3,5.3 Z M11.7,9.3 L9.7,7.3 L8.3,8.7 L10.3,10.7 L11.7,9.3 Z"/>
                </g>
              </svg>
              <span>
                {{ !selecting ? $t('contractsRenewal.selectTalents') : $t('contractsRenewal.unselectTalents')}}
              </span>
            </button>
          </div>

          <div class="contractsRenewal__cardsWrapper">
            <template v-if="!isLoading && talentCards.length || paginationActive">
              <div
                v-for="(talent, index) in talentCards"
                :key="talent.id + index"
              >
                <talent-card
                  :talent-card="talent"
                  :selectable="selecting"
                  :selected="!!selectedContracts.find(item => item.id == talent.id)"
                  :activeStatus="activeStatus"
                  @checked="toggleTalentCard($event)"
                  @renewTalent="renewTalent($event)"
                  @changeTalentContract="changeTalentContract($event)"
                  @rejectTalent="rejectTalent($event)"
                />
                <div v-if="index === talentCards.length - 1 && talentCards.length != listsCount?.[activeStatus]" class="contractsRenewal__pagination">
                  <span>
                    {{ talentCards.length }} / {{ listsCount?.[activeStatus] }}
                  </span>
                  <button @click="getNextPage()">
                    <!-- <img src="@/assets/images/contract-renewal/renew.svg" alt="Renew" /> -->
                    <span>{{ $t("contractsRenewal.more") }}</span>
                    <div v-if="isLoading" class="pagination-loader"></div>
                  </button>
                </div>
              </div>
            </template>
            <div v-else-if="!isLoading && !talentCards.length" class="tw-text-center tw-mt-12 tw-mb-12">
              <img src="@/assets/images/contract-renewal/no-contract.svg" alt="No Contracts" class="mx-auto tw-w-64">
              <p class="tw-text-xl tw-font-bold tw-mt-8 tw-text-black">
                {{ $t('contractsRenewal.emptyList') }}
              </p>
            </div>
            <SkeletonLoaderTalentCard v-else-if="isLoading && !paginationActive" />
          </div>
      </div>
    </div>
    <div v-if="selectedContracts.length > 1" class="contractsRenewal__groupActions">
      <div class="container">
        <p>
          <span>
            <b>{{ selectedContracts.length }}</b> {{ $t('contractsRenewal.talentsWereSelected') }}
          </span>
        </p>
        <div class="contractsRenewal__groupActionsCTAs">
          <button @click="isContractBulkRenewModalOpen = true">
            <img src="@/assets/images/contract-renewal/renew-white.svg" alt="Renew">
            <span>{{ $t('contractsRenewal.renew') }}</span>
          </button>
          <button @click="isContractBulkRejectModalOpen = true">
            <img src="@/assets/images/contract-renewal/reject-white.svg" alt="Reject">
            <span>{{ $t('contractsRenewal.reject') }}</span>
          </button>
        </div>
      </div>
    </div>
    <ContractRenewalChangeTermsModal :talentContract="talentContract" v-if="isContractRenewalChangeTermsModalOpen" :isOpen="isContractRenewalChangeTermsModalOpen" @update="isContractRenewalChangeTermsModalOpen = $event" @refresh="refresh(true, $event)"  />
    <ContractBulkRenewModal :selected-contracts="selectedContracts" v-if="isContractBulkRenewModalOpen" :isOpen="isContractBulkRenewModalOpen" @update="isContractBulkRenewModalOpen = $event" @refresh="refresh($event)"  />
    <ContractBulkRejectModal :selected-contracts="selectedContracts" v-if="isContractBulkRejectModalOpen" :isOpen="isContractBulkRejectModalOpen" @update="isContractBulkRejectModalOpen = $event" @refresh="refreshClientRejectRenewContractRequest($event)"  />
    <ContractRenewalModal :talentContract="talentContract" v-if="isContractRenewalModalOpen" :isOpen="isContractRenewalModalOpen" @update="isContractRenewalModalOpen = $event" @refresh="refresh"  />
    <RenewalStatusUpdate :status="renewalStatus" v-if="isRenewalStatusUpdate" :isOpen="isRenewalStatusUpdate" @update="isRenewalStatusUpdate = $event" />
    <ContractTermsChanged v-if="isContractTermsChanged" :isOpen="isContractTermsChanged" :is-inprogress="changeContractTermsInprogress" @update="isContractTermsChanged = $event" />
    <ClientRejectRenewContractRequest :talentContract="talentContract" v-if="isClientRejectRenewContractRequest" :isOpen="isClientRejectRenewContractRequest" @update="isClientRejectRenewContractRequest = $event" @refresh="refreshClientRejectRenewContractRequest" />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import TalentCard from '@/components/TalentsRenewalCard/index.vue';
import SkeletonLoaderTalentCard from '@/components/TalentsRenewalCard/SkeletonLoaderTalentCard.vue'
import ContractRenewalModal from "@/components/ContractRenewal/ContractRenewalModal.vue";
import ContractRenewalChangeTermsModal from "@/components/ContractRenewal/ContractRenewalChangeTermsModal.vue";
import RenewalStatusUpdate from "@/components/ContractRenewal/RenewalStatusUpdate.vue"
import ContractTermsChanged from "@/components/ContractRenewal/ContractTermsChanged.vue"
import ContractBulkRenewModal from "@/components/ContractRenewal/ContractBulkRenewModal.vue"
import ContractBulkRejectModal from "@/components/ContractRenewal/ContractBulkRejectModal.vue"
import ClientRejectRenewContractRequest from "@/components/ContractRenewal/ClientRejectRenewContractRequest.vue";


export default {
  name: "ContractsRenewal",
  components: {
    TalentCard,
    ContractRenewalModal,
    ContractRenewalChangeTermsModal,
    RenewalStatusUpdate,
    ContractTermsChanged,
    ClientRejectRenewContractRequest,
    ContractBulkRenewModal,
    ContractBulkRejectModal,
    SkeletonLoaderTalentCard
  },
  metaInfo() {
    const projectName = this.activeProject?.name
    return {
      title: `${projectName} | Squadio`,
    };
  },
  data() {
    return {
      selecting: false,
      isLoading: false,
      paginationActive: false,
      page: 1,
      activeStatus: "needs_renewal",
      selectedContracts: [],
      talentCards: [],
      isContractRenewalModalOpen: false,
      isContractRenewalChangeTermsModalOpen: false,
      isContractBulkRenewModalOpen: false,
      isContractBulkRejectModalOpen: false,
      isContractTermsChanged: false,
      isRenewalStatusUpdate: false,
      changeContractTermsInprogress: false,
      isClientRejectRenewContractRequest: false,
      talentContract: null,
      renewalStatus: "accepted",
      listsCount: null
    };
  },
  computed: {
    ...mapGetters({
      activeProject: "client/getActiveProject",
      user: "auth/getUser",
    }),
  },
  watch: {
    selecting(value) {
      if (!value) this.selectedContracts = [];
    },
    activeStatus(newStatus) {
      this.$router.push({ query: { status: newStatus } });
      this.selecting = false;
      this.selectedContracts = [];
      this.talentCards = [];
      this.page = 1;

      this.getContractsList();
    },
    activeProject(newVal, oldVal) {
      this.activeStatus = 'needs_renewal';
      this.selecting = false;
      this.selectedContracts = [];
      this.talentCards = [];
      this.page = 1;
      if (oldVal !== null && newVal.id !== oldVal?.id) {
        this.getContractsList();
      }
     
    },
    isContractBulkRenewModalOpen(value) {
      if(value) return;
      
      this.selecting = false;
      this.selectedContracts = [];
    },
    isContractBulkRejectModalOpen(value) {
      if(value) return;
      
      this.selecting = false;
      this.selectedContracts = [];
    }
  },
  created() {
    if (this.$route.query.status && ['needs_renewal', 'pending_talent', 'renewed', 'rejected'].includes(this.$route.query.status)) {
      this.activeStatus = this.$route.query.status;
    } else {
      this.activeStatus = 'needs_renewal';
      this.$router.push({ query: { status: 'needs_renewal' } });
    }
    this.getContractsList();
  },
  methods: {
    refresh(termsChanged = false, data) {
      if (!termsChanged) { 
        this.renewalStatus = "accepted"
        this.isRenewalStatusUpdate = true
        this.selecting = false;
        this.selectedContracts = [];
        this.talentCards = [];
      } else {
        this.isContractTermsChanged = true
        this.selecting = false;
        this.selectedContracts = [];
        this.talentCards = [];
        if (data.data.renewal_state == "talent_pending") this.changeContractTermsInprogress = true;
        else this.changeContractTermsInprogress = false;
      }
      this.getContractsList();
    },
    refreshClientRejectRenewContractRequest() {
      this.renewalStatus = "rejected"
      this.selecting = false;
      this.selectedContracts = [];
      this.isRenewalStatusUpdate = true
      this.isClientRejectRenewContractRequest = false
      this.talentContract = null
      this.talentCards = [];
      this.getContractsList();
    },
    renewTalent(contract) {
      this.talentContract = contract
      this.isContractRenewalModalOpen = true
    },
    changeTalentContract(contract) {
      this.talentContract = contract
      this.isContractRenewalChangeTermsModalOpen = true
    },
    rejectTalent(contract) {
      this.talentContract = contract
      this.isClientRejectRenewContractRequest = true
    },
    toggleTalentCard(talent) {
      const index = this.selectedContracts.findIndex(item => item.id === talent.id);
      if (index !== -1) {
        this.selectedContracts.splice(index, 1);
      } else {
        this.selectedContracts.push(talent);
      }
    },
    toggleSelectingCards() {
      if (!this.talentCards.length || this.activeStatus != 'needs_renewal') return;
      this.selecting = !this.selecting
    },
    getNextPage() {
      this.page++;
      this.paginationActive = true;
      this.getContractsList();
    },
    async getContractsList() {
      this.isLoading = true;
      try {
        await this.axios
          .get(`/api/client/contract-renewal?status=${this.activeStatus}&page=${this.page}`)
          .then((res) => {
            this.talentCards = [ ...this.talentCards, ...res.data.data];
            this.listsCount = res.data.lists_count
            if (this.talentCards.length) {
              const activeProject = JSON.parse(localStorage.getItem("activeProject"));
              activeProject.name = this.talentCards[0].project_name;
              localStorage.setItem("activeProject", JSON.stringify(activeProject));
            }
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
}
</script>

<style lang="scss">
.contractsRenewal {
  &__wrapper {
    position: relative;
    z-index: 0;
    background-color: #fff;
    padding: 45px 20px;
    border-radius: 18px;
    margin: 60px auto;

    @media (min-width: 1200px) {
      width: 84%;
    }
    @media (min-width: 1400px) {
      width: 70%;
    }
  }

  &__mainHeading {
    font-size: 30px;
    display: flex;
    justify-content: flex-start;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: 20px;
    overflow-x: scroll;
    margin-top: 24px;
    padding: 8px 65px;
    border-radius: 30px;
    box-shadow: 9px 7px 45px 0 rgba(66, 46, 135, 0.09);
    scrollbar-width: none;
    margin-bottom: 24px;


    @media (max-width: 768px) {
      padding: 8px 15px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    * {
      scrollbar-width: none;
    }
  }

  &__navItem {
    border-radius: 30px;
    padding: 8px 9px;
    display: flex;
    align-items: center;
    color: #494d55;
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    min-width: 180px;
    justify-content: center;

    &.active {
        background-color: rgba(255, 198, 41, 1);
        color: #1e2022;
        font-weight: bold;
    }

    .loader {
      margin-inline-start: 7px;
    }
  }

  &__navItemCount {
    width: 34px;
    height: 34px;
    margin-inline-start: 7px;
    border-radius: 100%;
    background-color: rgba(241, 241, 241, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;

    &--visible {
      visibility: visible;
    }
  }

  &__multipleSelect {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 250px;
      padding: 16px;
      border: 1px solid rgba(241, 241, 241, 1);
      border-radius: 12px;
      transition: box-shadow .3s ease;

      svg {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }
    }

    &.disabled {
      button {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &__groupActions {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: #fff;
    z-index: 10;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding-block: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    p {
      font-size: 16px;
      color: #000;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 160px;
      padding: 16px;
      border-radius: 12px;
      color: #fff;
      transition: box-shadow .3s ease;

      img {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }
    }
  }

  &__groupActionsCTAs {
    display: flex;
    gap: 16px;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
    }

    button:first-child {
      background-color: #26daa6;
    }
    button:last-child {
      background-color: #ff6f59;
    }
  }

  &__pagination {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;

    span {
      font-size: 14px;
      color: #000;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 10px 40px;
      border-radius: 12px;
      color: #fff;
      transition: box-shadow .3s ease;
      box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);

      img {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.pagination-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid rgba(255, 198, 41, 1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

html[dir="rtl"] {
  .contractsRenewal__mainHeading {
    /* flex-direction: row-reverse; */
    justify-content: flex-start;
  }
}
</style>