<template>
  <transition name="modal-fade">
    <div class="modal align-items-center contract-bulk-renewal-modal" role="dialog" aria-labelledby="modalTitle"
      aria-describedby="modalDescription" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header removeTitleBorder">
            <div class="w-100 tw-flex tw-flex-col">
              <h2 class="tw-mt-2 tw-text-[19px] md:tw-text-[35px] tw-font-bold tw-text-primary tw-text-center">
                {{ $t("contractsRenewal.mainHeading") }}
              </h2>
            </div>
          </div>
          <div class="modal-body no-border !tw-p-0 tw-text-center">
            <hr />
            
            <div class="tw-flex tw-items-center tw-justify-center">
              <div class="imagesWrapper tw-flex tw-mx-auto tw-rounded-full tw-bg-[#ececec] tw-p-[5px]">
                <img
                    v-for="contract in selectedContracts.slice(0, 3)"
                    :key="contract.id"
                    class="tw-w-[50px] tw-h-[50px] tw-rounded-full tw-object-cover tw-border tw-border-[#ececec]"
                    :src="talentProfilePic(contract.talent)"
                    :alt="contract.talent.name"
                    @error="imageLoadOnError"
                />
                <div class="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-[#422e87] tw-text-[#ffffff] tw-h-[50px] tw-font-semibold tw-text-[18px] tw-px-2">
                  {{ contractsCount }} {{ $t("contractsRenewal.talents") }}
                </div>
              </div>
            </div>
            
            <p class="tw-text-[#202020] tw-text-[23px] md:tw-text-[28px] tw-text-center tw-mt-4 tw-font-semibold">
              {{ $t("contractsRenewal.areYouSureToRenewContracts") }}
            </p>
          </div>

          <hr />
          <div class="modal-footer tw-p-0">
            <button type="button"
              class="btn cancelbtn btnstyleUpdate  tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[150px]"
              data-bs-dismiss="modal" @click="close">
              {{ $t("talentWizard.cancel") }}
            </button>
            <button :disabled="isLoading" @click="confirm" type="submit"
              class="btn btn-primary saveBtn tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[150px] ">
              {{ $t("nomination.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import toast from "../../services/toast.js";

export default {
  name: "contractBulkRenewalModal",
  props: ["isOpen", "selectedContracts"],
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      isLoading: false
    };
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  computed: {
    contractsCount() {
      return this.selectedContracts.length;
    }
  },
  methods: {
    talentProfilePic(talent) {
      if (talent.image) {
        return `${talent.image}`;
      }
      else return require("@/assets/images/talent-avatar.png");
    },
    async confirm() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        await this.axios
          .post(`/api/client/contract-renewal/renew`, {
            contract_ids: this.selectedContracts.map(c => c.id)
          })
          .then(() => {
            this.$emit('refresh')
            this.close()
          });
      } catch (error) {
        console.error(error);
        toast.error(error.response.data?.message || error.message);
      } finally {
        this.isLoading = false;
      }
    },
    getJobTitle(type) {
      const jobs = [
        {
          text: this.$t("contractsRenewal.contractType.remote"),
          value: "remote"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-accommodation"),
          value: "onsite-accommodation"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite"),
          value: "onsite"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-business-visa"),
          value: "onsite-business-visa"
        },
        {
          text: this.$t("contractsRenewal.contractType.hybrid"),
          value: "hybrid"
        }
      ];

      return jobs.find(j => j.value == type).text;
    },
    getProfilePlaceholderImage() {
      if (this.miniProfile)
        return require("@/assets/images/ic_avatar-border.png");
      else return require("@/assets/images/talent-avatar.png");
    },
    imageLoadOnError(e) {
      e.target.src = this.miniProfile
        ? require("@/assets/images/ic_avatar-border.png")
        : require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent";
    },
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
.modal-dialog-scrollable .modal-content {
  max-height: 90%;
  overflow: auto;
}
.imagesWrapper {
  img:not(:first-child),
  & > div {
    margin-inline-start: -15px;
  }
}
</style>


<style lang="scss">
.modal-open {
  overflow: hidden;
}

.contract-bulk-renewal-modal {
  hr {
    border: 1px solid #ebebeb;
    margin: 25px 0;
  }
}

.contract-bulk-renewal-modal .modal-dialog {
  width: 95%;
  margin: 4rem auto;

  @media (min-width: 576px) {
    width: 60%;
  }

  margin: 0 auto;

  .modal-body.no-border {
    overflow: auto;
  }

  .modal-content {
    @media (min-width: 576px) {
      padding: 20px 30px;
    }
  }
}

@media (min-width: 576px) {
  .contract-bulk-renewal-modal .modal-dialog {
    max-width: 811px;
  }
}

@media (max-width: 800px) {
  .contract-bulk-renewal-modal .modal-content {
    padding: 20px 19px;
  }
}
</style>

<style scoped>
@media (min-width: 992px) {
  .talentCard__details {
    grid-template-columns: repeat(3, minmax(0, 1fr));
     gap: 3rem;
  }
}
</style>