<template>
  <transition name="modal-fade">
    <div class="modal align-items-center contract-renewal-modal" role="dialog" aria-labelledby="modalTitle"
      aria-describedby="modalDescription" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header removeTitleBorder">

            <div class="w-100 tw-flex tw-flex-col">
              <h2 class="tw-mt-2 tw-text-[19px] md:tw-text-[35px] tw-font-bold tw-text-primary tw-text-center">
                {{ $t("contractsRenewal.contractRenewal") }}
              </h2>

            </div>
          </div>
          <div class="modal-body no-border !tw-p-0">

            <hr />
            <p
              class="tw-text-[#202020] tw-text-[23px] md:tw-text-[28px] tw-text-center  md:tw-text-start md:tw-mx-6  tw-font-semibold">
              {{ $t("contractsRenewal.areYouSureToRenew") }}
            </p>

            <div class="tw-bg-[#F4F1FD] tw-rounded-[15.75px] tw-p-[18px] tw-my-4 md:tw-mx-6 tw-mx-0 tw-flex">
              <div class="tw-flex">
                <img :src="talentProfilePic"
                  class="img-fluid md:tw-w-14 md:tw-h-14 tw-w-9 tw-h-9 tw-rounded-full tw-object-cover"
                  :class="talentContract && talentContract.talent.image ? 'tw-border-[rgba(228, 228, 228, 1)] tw-border-[5.63px]' : ''" />
                <img v-if="false" class="talent-real-img tw-w-14 tw-h-14" @error="imageLoadOnError" />
              </div>
              <div class="tw-flex tw-flex-col tw-justify-center tw-ms-4">
                <div class="tw-flex tw-font-bold tw-text-[#292929] tw-text-[12.91px] md:tw-text-[15.59px]">
                  {{ talentContract.talent.full_name }}
                  <span class="tw-ms-2">
                    <img class="tw-w-5 tw-h-5" :src="talentContract.talent.qualified
                        ? require('@/assets/images/ic_verifed.svg')
                        : require('@/assets/images/ic_unverifed.svg')
                      " />
                  </span>
                </div>
                <div class="tw-flex tw-items-center">
                  <div class="  tw-text-[11] md:tw-text-[12.99px] tw-text-primary tw-font-semibold">
                    {{ talentContract.talent.role?.name }}
                  </div>
                  <template v-if="Number(talentContract.talent.experience_years) > 0 && talentContract.talent.experience_years != null">
                    <span class="tw-ms-2 tw-text-[11px] md:tw-text-[12.99px]] tw-text-[#9f9d9d] tw-font-medium">
                      {{ talentContract.talent.experience_years }} {{ talentContract.talent.experience_years == 1 ? $t('nomination.yearOfExperience') : $t('nomination.yearsExperience') }}
                    </span>
                  </template>
                  <span v-else-if="Number(talentContract.talent.experience_years) == 0 && talentContract.talent.experience_years != null" class="tw-ms-2 tw-text-[11px] md:tw-text-[12.99px]] tw-text-[#9f9d9d] tw-font-medium">
                    {{ $t('matchedTalents.fresh') }}
                  </span>
                </div>
              </div>
            </div>

            <h4
              class="md:tw-text-[19px] tw-text-[13.3px] tw-text-[#202020] tw-font-semibold tw-text-start md:tw-mx-6 tw-mx-0">
              {{ $t("contractsRenewal.duration") }}
            </h4>

            <div
              class="tw-bg-[#FAFAFA] tw-rounded-[8.89px] tw-p-[18px] tw-my-4 md:tw-mx-6 tw-mx-0 tw-border-[#f1f1f1] tw-flex  tw-justify-around ">
              <div class="tw-flex tw-flex-row">
                <div class="tw-me-2">
                  <img class="tw-w-6 tw-h-6" src="@/assets/images/client/calendar-clock_copy.svg" alt="" srcset="" />
                </div>
                <div class="tw-flex tw-flex-col">
                  <span class="tw-text-[#1e2022] tw-text-[12.16px] md:tw-text-[17.78px] tw-font-medium">{{
                    talentContract.renewal_terms.start_date}}</span>
                  <span class="tw-text-[#9f9d9d] tw-text-[10.64px] md:tw-text-[15.56px] tw-font-normal">{{
                    $t('contractsRenewal.contractStart') }}</span>
                </div>
              </div>
              <div class="tw-flex tw-my-auto tw-mx-10">
                <img class="tw-w-[33.32px]"
                :class="{ 'tw-rotate-180': $i18n.locale == 'ar' }" src="@/assets/images/client/arrow-right.svg" alt="" srcset="" />
              </div>
              <div class="tw-flex tw-flex-row">
                <div class="tw-me-2">
                  <img class="tw-w-6 tw-h-6" src="@/assets/images/client/calendar-clock_copy.svg" alt="" srcset="" />
                </div>
                <div class="tw-flex tw-flex-col">
                  <span class="tw-text-[#1e2022]  tw-text-[12.16px] md:tw-text-[17.78px] tw-font-medium">{{
                    talentContract.renewal_terms.end_date}}</span>
                  <span class="tw-text-[#9f9d9d]  tw-text-[10.64px] md:tw-text-[15.56px] tw-font-normal">{{
                    $t('contractsRenewal.contractEnd') }}</span>
                </div>
              </div>
            </div>

            <div class="talentCard__details  tw-p-[18px] tw-my-4 md:tw-mx-6 tw-mx-0">
              <div>
                <img src="@/assets/images/contract-renewal/remote-working.svg" alt="Remote working" />
                <h4>
                  {{ getJobTitle(talentContract.renewal_terms.job_type) }}
                  <span>
                    {{ $t("contractsRenewal.contractType.main") }}
                  </span>
                </h4>
              </div>
              <div>
                <img src="@/assets/images/contract-renewal/time-quarter-to.svg" alt="Remote working" />
                <h4>
                  {{ talentContract.renewal_terms.contract_type == 1 ? $t('contractsRenewal.contractType.partTime') :
                    talentContract.renewal_terms.contract_type == 2 ? $t('contractsRenewal.contractType.fullTime') : $t('contractsRenewal.contractType.monthlyHours') }}
                  <span>
                    {{ $t("contractsRenewal.teamAvailability") }}
                  </span>
                </h4>
              </div>
              <div>
                <img src="@/assets/images/contract-renewal/finance.svg" alt="Remote working" />
                <h4>
                  {{ talentContract.renewal_terms.salary.total_salary }} {{ $t("contractsRenewal.usd") }}
                  <span>
                    {{ $t("contractsRenewal.salary") }}
                  </span>
                </h4>
              </div>
            </div>


            <hr />
          </div>
          <div class="modal-footer tw-p-0">
            <button type="button"
              class="btn cancelbtn btnstyleUpdate  tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[162.75px]"
              data-bs-dismiss="modal" @click="close">
              {{ $t("talentWizard.cancel") }}
            </button>
            <button :disabled="isLoading" @click="confirm" type="submit"
              class="btn btn-primary saveBtn tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[162.75px] ">
              {{ $t("nomination.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import toast from "../../services/toast.js";

export default {
  name: "contractRenewalModal",
  props: ["isOpen", "talentContract"],
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      isLoading: false
    };
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  computed: {
    talentProfilePic() {
      if (this.talentContract && this.talentContract.talent.image) {
        return `${this.talentContract.talent.image}`;
      }
      else return require("@/assets/images/talent-avatar.png");
    }
  },
  methods: {
    async confirm() {
      this.isLoading = true;
      try {
        await this.axios
          .post(`/api/client/contract-renewal/${this.talentContract.id}/renew`)
          .then(() => {
            this.$emit('refresh')
            this.close()
          });
      } catch (error) {
        console.error(error);
        toast.error(error.response.data?.message || error.message);
      } finally {
        this.isLoading = false;
      }
    },
    getJobTitle(type) {
      const jobs = [
        {
          text: this.$t("contractsRenewal.contractType.remote"),
          value: "remote"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-accommodation"),
          value: "onsite-accommodation"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite"),
          value: "onsite"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-business-visa"),
          value: "onsite-business-visa"
        },
        {
          text: this.$t("contractsRenewal.contractType.hybrid"),
          value: "hybrid"
        }
      ];

      return jobs.find(j => j.value == type).text;
    },
    getProfilePlaceholderImage() {
      if (this.miniProfile)
        return require("@/assets/images/ic_avatar-border.png");
      else return require("@/assets/images/talent-avatar.png");
    },
    imageLoadOnError(e) {
      e.target.src = this.miniProfile
        ? require("@/assets/images/ic_avatar-border.png")
        : require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent";
    },
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>


<style scoped>
.modal-dialog-scrollable .modal-content {
  max-height: 90%;
  overflow: auto;
}
</style>


<style lang="scss">
.modal-open {
  overflow: hidden;
}



.contract-renewal-modal {
  hr {
    border: 1px solid rgba(127, 127, 127, 1);
    margin: 25px 0;
  }
}

.contract-renewal-modal .modal-dialog {
  width: 95%;
  margin: 4rem auto;

  @media (min-width: 576px) {
    width: 60%;
  }

  margin: 0 auto;

  .modal-body.no-border {
    overflow: auto;
  }

  .modal-content {
    @media (min-width: 576px) {
      padding: 20px 30px;
    }
  }
}

@media (min-width: 576px) {
  .contract-renewal-modal .modal-dialog {
    max-width: 811px;
  }
}

@media (max-width: 800px) {
  .contract-renewal-modal .modal-content {
    padding: 20px 19px;
  }
}
</style>

<style scoped>
@media (min-width: 992px) {
  .talentCard__details {
    grid-template-columns: repeat(3, minmax(0, 1fr));
     gap: 3rem;
  }
}
</style>